.react-flow__node-selectorNode {
  font-size: 12px;
  background: #eee;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.cutomernode {
  border-color: #44F27E;
  border-width: 2px;
  border-style: solid;
  background-color: white;
}
.ornode
{

}