.leaflet-container {
  width: 100%;
  height: 100vh;
  overflow: auto;
  /* margin: 10px; */
}

.leaflet-container.crosshair-cursor-enabled {
  cursor: crosshair;
}

.dropdowns {
  background-color: #dedede;
  color: #080404;
  border-radius: 5px;
  cursor: pointer;
  height: 34px;
  font-size: 13px;
  border-width: 0px;
  font-weight: bold;
}

.dropdowns:hover {
  border-color: #ffffff;
}

.DropDownComments {
  border-color: #bee1ff;
  border-width: 2px;
  right: 26px;

  width: 140px;
  position: absolute;
  border-radius: 5px;
  color: #0f0000;
}

.dropdownsoprationHeader {
  cursor: pointer;
  height: 36px;
  padding-bottom: 5px;
  padding-top: 3px;
  text-align: center;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 400;
  background-color: #50687d;
  font-family: sans-serif;
  color: white;
}

.dropdownsoprationHeader {
  border-color: white;
}

.dropdownsoprationHeader:checked {
  border-color: white;
}

.vlaueDropDown {
  font-family: sans-serif;
}

.userDropdown {
  width: 100%;
  border-style: solid 1px;
  border-color: #e2e2e2;
  cursor: pointer;
  height: 34px;
  font-size: 13px;
  border-width: 1px;
}

.input-box-DB {
  display: flex;
  align-items: center;
  max-width: 300px;
  background: #fff;
  border: 1px solid #a0a0a0;
  border-radius: 4px;
  padding-left: 0.5rem;
  overflow: hidden;
  font-family: sans-serif;
}

.input-box-DB .prefix {
  font-weight: 300;
  font-size: 14px;
  margin-right: 6px;
  color: #999;
}

.input-box-DB input {
  flex-grow: 1;
  font-size: 14px;
  background: #fff;
  border: none;
  outline: none;
  padding: 0.5rem;
}

.input-box-DB:focus-within {
  border-color: #777;
}

.blurData {
  backdrop-filter: grayscale(30%);
}

.internet-disable {
  background-color: #080404;
}

.slider {
  align-items: center;
}

.slider label {
  font-size: 18px;
  border-radius: 5px;
  background-color: rgb(10, 29, 149);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Open Sans;
  margin-left: 20px;
  color: rgb(255, 255, 255);
}

.slider input[type="range"] {
  background: black;
  cursor: pointer;
  border: none;
  outline: none;
}

@media (min-width: 992px) {
  .my-modal-lg {
    width: auto;
  }
}

@media (min-width: 768px) {
  .my-modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .my-modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }
}