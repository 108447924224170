.deviceCard {
  background-color: aliceblue;
}

.deviceCard:hover {
  background-color: rgb(214, 230, 243);
}

.frontImg {
  width: 100%;
  height: 60px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cbtndev {
  background-color: rgb(138, 138, 138);
  width: 70px;
  color: white;
}

.cbtndev:hover {
  background-color: rgb(203, 7, 92);
  width: 100px;
  transition: 0.5s;
  color: white;
  cursor: pointer;
}

.speedDialcomponent {
  display: block;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.imglogosidebar {
  object-fit: contain;
  width: 140px;
  margin-top: 70px;
  margin-bottom: 10px;
  height: 60px;
}

.circle {
  width: 200px;
  color: #000000;
  height: 200px;
  margin: 20px;
  object-fit: contain;
  background: rgb(255, 255, 255);
  margin: auto;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

.circleminilogo {
  width: 50px;
  color: #000000;
  height: 50px;
  margin: 20px;
  object-fit: contain;
  background: rgb(255, 255, 255);
  margin: auto;

}

.iconHVR {
  color: black;
  /* background-color: #c9c9c9; */
  width: 40px;
  height: 40px;
 
  cursor: pointer;
}

.iconHVR:hover {
  color: blue;
}


.imglogosidebarmini {
  object-fit: fill;
  width: 60%;
  margin-left: 10px;
  margin-top: 10px;
  /* margin-bottom: 10px; */
  height: 60%;
}

/* front */
.boxsideBox {
  height: 100%;
  /* width: 55px; */
  margin-right: 18px;
  text-align: center;
  /* position: fixed;
  background-color: black;
  text-align-last: center; */
}

.boxsideBox2 {
  margin-left: 20px;
}

.mainfrontBox {
  margin-left: 40px;
  border-radius: 5px;
  box-shadow: -10px 10px 15px 4px #02020252;
  margin-right: 40px;
  margin-top: 10px;
  border-width: 10px;
  margin-bottom: 10px;
  border-color: rgba(78, 57, 57);
  background-color: rgb(78, 57, 57);
  border-style: solid;
}

.anableFrontEdfa {
  margin-left: 40px;
  border-radius: 5px;
  margin-right: 40px;
  margin-top: 10px;
  border-width: 10px;
  margin-bottom: 10px;
}

.inerBox {
  border-radius: 8px;
  background: rgb(198, 193, 230);
  padding: 1px;
  /* width: 50%; */
  font-weight: 700;
  -moz-box-shadow: inset 0 0 5px #000000;
  -webkit-box-shadow: inset 0 0 5px #000000;
  box-shadow: inset 5px 5px 5px #000000;
}

.displayScreen {
  border: 5px solid rgb(78, 57, 57);
  margin: 5px;
  -moz-box-shadow: inset 0 0 5px #000000;
  -webkit-box-shadow: inset 0 0 5px #000000;
  box-shadow: inset 2px 2px 7px #000000;
  border-radius: 5px;
  height: 80px;
  background-color: #b2bdaa;
}

.roundBtn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: table-cell;
  color: rgb(78, 25, 15);
  vertical-align: middle;
  text-align: center;
  border: 0.1px solid rgb(19, 2, 2);
  background: -moz-linear-gradient(top,
      #b4d7ff 0%,
      #96bee0 50%,
      #96bee0 51%,
      #cfeaff 100%);
}

.roundinner {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 auto;
  border: 0.1px solid rgb(102, 134, 168);
  background: -moz-linear-gradient(top,
      #b4d7ff 0%,
      #96bee0 50%,
      #96bee0 51%,
      #cfeaff 100%);
}

.centerdot {
  width: 5px;
  height: 5px;
  margin-top: 12px;
  margin-left: 12px;
  border-radius: 50%;
  background-color: black;
  position: relative;
}

img {
  pointer-events: none;
}

/* back */
.boxsidebackBox {
  height: 100%;
  width: 35px;
  margin-right: 2px;
  /* text-align: inherit; */
  /* position: fixed; */
  /* background-color: black; */
  /* text-align-last: center; */
}

.boxsidebackBox2 {
  height: 100%;
  width: 35px;
  margin-right: 2px;
}

.mainbackBox {
  margin-left: 40px;
  border-radius: 5px;
  box-shadow: -10px 10px 15px 4px #02020252;
  margin-right: 40px;
  margin-top: 10px;
  border-width: 10px;
  margin-bottom: 10px;
  border-color: rgba(78, 57, 57);
  background-color: rgb(78, 57, 57);
  border-style: solid;
}

.inerbackBox {
  border-radius: 8px;
  background: rgb(198, 193, 230);
  -moz-box-shadow: inset 0 0 5px #000000;
  -webkit-box-shadow: inset 0 0 5px #000000;
  box-shadow: inset 5px 5px 5px #000000;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.mobileHeaderMap{
  display: none;
}
@media (max-width: 700px) {
  .greenport {
    width: 50%;
  }

  .blueport {
    width: 50%;
  }

  .blueportedfa {
    width: 38%;
  }


}

@media (max-width: 1092px) {
  .anableFrontEdfa {
    display: flex;
    margin-left: 10px;
    border-radius: 5px;
    margin-right: 10px;
    margin-top: 10px;
    border-width: 10px;
    margin-bottom: 10px;
  }
  .mapstyleglobal{
    margin-top: 0;
  }
  .headerformobile{
    display: none;
  }
  .mobileHeaderMap{
    display: block;
  }
  .speedDialcomponent {
    display: block;
  }

  .mainfrontBox {
    display: none;
  }

  .mainbackBox {
    display: none;
  }
}

.inputsm {
  width: 50px;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(182, 182, 182);
  background-color: honeydew;
}

.inputmd {
  width: 80px;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(182, 182, 182);
  background-color: honeydew;
}

.dashbox:hover {
  background-color: #94c7f149;
  color: "white";
}